export default {
    groups: 'Группы',
    group: 'Группа',
    services: 'Услуги',
    object: 'Объект',
    building: 'Строение',
    entrance: 'Подъезд',
    room: 'Квартира',
    m: 'месяц',
    q: 'квартал',
    y: 'год',
    company: 'Компания',
    individual: 'Физ лицо',
    new: 'Новый',
    active: 'активный',
    ended: 'не активный',
    main: 'Основной',
    months: {
        january: 'Январь',
        february: 'Февраль',
        march: 'Март',
        april: 'Апрель',
        may: 'Май',
        june: 'Июнь',
        july: 'Июль',
        august: 'Август',
        september: 'Сентябрь',
        october: 'Октябрь',
        november: 'Ноябрь',
        december: 'Декабрь'
    },
    true: 'Да',
    false: 'Нет',
    quittance: 'Квитанция',
    pretension: 'Претензия',
    envelope: 'Конверт',
    printer: 'Печать',
    working: 'Работает',
    broken: 'Не исправен',
    on: 'Включен',
    off: 'Выключен'
}
