<template lang="html">
    <div class="block">
        <div class="row mt-2 ml-1">
            <div class="col">
                <button type="button" class="btn btn-outline-dark " @click="save">Сохранить и закрыть</button>
                <button type="button" class="btn btn-outline-dark ml-1  " @click="close">Закрыть</button>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 ml-1">
            <div class="card" @keypress.enter="save">
                <!-- <div class="card-header text-center">
                    Данные пользователя
                </div> -->
                <div class="card-body">
                    <h6 class="card-title mb-4">Данные пользователя:</h6>
                    <div class="form-group form-group-sm row">
                    <label class="control-label col-sm-2 text-right" for="fio">ФИО</label>
                    <div class="col-sm-8">
                        <input class="form-control form-control-sm" id="fio" v-model.trim="user['username']">
                    </div>
                    </div>
                    <div class="form-group form-group-sm row">
                    <label class="control-label col-sm-2 text-right" for="fio">Логин</label>
                    <div class="col-sm-8">
                        <input class="form-control form-control-sm" id="fio" v-model.trim="user['login']">
                    </div>
                    </div>
                    <div class="form-group form-group-sm row">
                    <label class="control-label col-sm-2 text-right" for="fio">Пароль</label>
                    <div class="col-sm-8">
                        <input class="form-control form-control-sm" id="fio" v-model.trim="user['password']">
                    </div>
                    </div>
                </div>
            </div>
            <div class="card ml-1">
                <div class="card-body">
                    <h6 class="card-title mb-4">Права доступа:</h6>
                    <div class="">
                        <input type="checkbox" id="admin" value="admin" v-model="user.groups">
                        <label for="admin"><span></span> Администратор</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['soft', 'id'],
    data(){
        return {
            user: this.soft.get('users', this.id)
        }
    },
    methods: {
        save(){
            this.user.login = this.user.login.toLowerCase();
            var data = {act: 'save', base: 'users', query: this.user, id: this.user._id}
            this.soft.send(data);
            this.close();
        },
        close(){
            this.$emit('close');
        }
    },
    computed: {
    }
}
</script>

<style lang="css">
</style>
