<template>
    <div class="block">
        <div>
            <div class="row">
                <div class="col-1">
                    <button type="button" name="button" class="btn btn-outline-dark" @click="add_task = true">Добавить</button>
                </div>
                <div class="col-1">
                    <div class="btn-group" role="group" v-if="checked_tasks.length > 0">
                        <button id="actBtn" type="button" class="btn btn-outline-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true">
                            Действия
                        </button>
                        <div class="dropdown-menu" aria-labelledby="actBtn">
                            <a class="dropdown-item text-dark bg-light" @click="complete">Выполнить</a>
                            <a class="dropdown-item text-dark bg-light" @click="remove()">Удалить</a>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col mt-3">
                    <span class="badge pointer" @click="showCompleted" v-if="show_completed">Показать текущие</span>
                    <span class="badge pointer" @click="showCompleted" v-else>Показать выполненные</span>
                </div>
            </div>
            <div class="row">
                <div class="col-5 mt-1">
                    <div class="card pointer" v-for="task, key in soft.getAll('tasks')"
                        :id="key"
                        :key="task._id"
                        @click="show(task)"
                    >
                        <div class="card-body pt-2 pb-2">
                            <div class="row">
                                <div class="col-1"  v-if="!task.completed">
                                    <input type="checkbox" :id="task._id+key" @click.stop v-model="checked_tasks" :value="task" >
                                    <label :for="task._id+key" @click.stop><span></span></label>
                                </div>
                                <div class="col-9 font-weight-bold">
                                    {{ task.name }}
                                </div>
                                <div class="col-2 task-date text-black-50 text-right ">
                                    <span class="align-top">{{ soft.nDate(task.createDate) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <addTask v-if="add_task" :soft="soft" @close="add_task = false">
        </addTask>
        <Task v-if="show_task" :soft="soft" :id="active_task" @close="show_task = false">
        </Task>
    </div>
</template>

<script>
import addTask from './modals/addTask.vue';
import Task from './modals/Task.vue';

export default {
    props: ['soft'],
    data(){
        return{
            add_task: false,
            show_task: false,
            show_completed: false,
            active_task: {},
            checked_tasks: []
        }
    },
    components: {
        addTask,
        Task
    },
    methods: {
        show(task){
            this.show_task = true;
            this.active_task = task._id;
        },
        close(){
            this.show_detail = false;
        },
        showCompleted(){
            this.show_completed = !this.show_completed;
            var data = {act: 'show', base: 'tasks', completed: this.show_completed}
            this.soft.send(data);
        },
        complete(){
            for (const task of this.checked_tasks) {
                task.completed = true;
                var data = {act: 'save', base: 'tasks', query: task}
                this.soft.send(data);
            }
        },
        remove(){
            for (const task of this.checked_tasks) {
                var data = {act: 'remove', base: 'tasks', id: task._id}
                this.soft.send(data);
            }
        }
    },
    mounted(){
        var data = {act: 'show', base: 'tasks', completed: false}
        this.soft.send(data);
    }
}
</script>


<style type="text/css" scoped>
.task-date {
    font-size: 0.7em;
}
</style>