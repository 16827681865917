<template lang="html">
    <div class="block">
        <div v-if="!edit_partner">
            <div class="row  mt-2 ml-1">
                <div class="btn btn-outline-dark mr-2" @click="save">
                    Сохранить
                </div>
                <div class="btn btn-outline-dark" @click="close">
                    Закрыть
                </div>
            </div>
            <div class="col-4">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title mb-1">{{ partner.name }}</h6>
                        <div class="row">
                            <div v-if="partner.type == 'ЮЛ'" class="info ml-3">
                                ИНН: {{ partner.inn }} КПП: {{ partner.kpp }}
                            </div>
                            <div v-if="partner.type == 'ФЛ'" class="info ml-3">
                                Паспорт: {{ partner.pasport }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title mb-1">Контакты:</h6>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="contact, key in partner.contacts" :key="key" @click="editItem(key, 'contacts')">
                                <span class="oi oi-trash float-right" @click.stop="removeItem(key, 'contacts')"></span>
                                <p class="info">{{ contact.name }}</p>
                                <p class="info">{{ contact.position }} {{ contact.fio }}</p>
                                <p class="info">{{ contact.phone }}</p>
                                <p class="info">{{ contact.email }}</p>
                            </li>
                        </ul>
                        <span class="oi oi-plus mt-2 ml-2" @click="editItem('add', 'contacts')"></span>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title mb-1">Адреса:</h6>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="address, key in partner.addresses" :key="key" @click="editItem(key, 'addresses')">
                                <span class="oi oi-trash float-right" @click.stop="removeItem(key, 'addresses')"></span>
                                <p class="info mt-2">{{ address.type.values[0].value }}:</p>
                                <p class="info">{{ address.val }}</p>
                            </li>
                        </ul>
                        <span class="oi oi-plus mt-2 ml-2" @click="editItem('add', 'addresses')"></span>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title mb-1">Банковские счета:</h6>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="ac, key in partner.acs" :key="key" @click="editItem(key, 'acs')">
                                <span class="oi oi-trash float-right" @click.stop="removeItem(key, 'acs')"></span>
                                <p class="info">{{ ac.name }}</p>
                                <p class="info">Счет: {{ ac.number }}</p>
                                <p class="info">Кор. счет: {{ ac.kor }}</p>
                                <p class="info">БИК: {{ ac.bik }}</p>
                            </li>
                        </ul>
                        <span class="oi oi-plus mt-2 ml-2" @click="editItem('add', 'acs')"></span>
                    </div>
                </div>
            </div>
            <div class="col-6">

            </div>
        </div>
        <component v-if="show_item" v-bind:is="show_item" :data="item" :item_id="item_id" :soft="soft"  @close="show_item = false" @save="saveItem"></component>
    </div>
</template>

<script>

import Contact from './Contact.vue';
import AC from './AC.vue';
import Address from './Address.vue';

export default {
    props: ['soft', 'id'],
    data(){
        return {
            partner: this.soft.get('partners', this.id),
            edit_partner: false,
            item: {},
            item_id: '',
            show_item: false
        }
    },
    components: {
        'contacts': Contact,
        'acs': AC,
        'addresses': Address
    },
    methods: {
        close(){
            this.$emit('close');
        },
        save(){
            this.soft.send({act: 'save', base: 'partners', query: this.partner})
        },
        removeItem(id, base){
            this.partner[base].splice(id, 1);
        },
        editItem(id, base){
            if(id == 'add'){
                this.item = {}
                this.item_id = id;
                this.show_item = base;
            }else{
                this.item = this.partner[base][id];
                this.item_id = id;
                this.show_item = base;
            }
        },
        saveItem(data){
            if(data.item_id == 'add'){
                this.partner[this.show_item].push(data.item);
            }else{
                this.partner[this.show_item].splice(this.item_id, 1, data.item)
            }
            this.show_item = false;
        }
    },
    mounted(){
    }
}
</script>

<style type="text/css" scoped>
.info {
    font-size: 0.8rem;
}

p.info {
    margin: 0;
}

.oi {
    font-size: 0.8rem;
}
</style>