<template lang="html">
    <transition name="modal">
        <div id="modal-mask" class="modal-m" @click.self="close" @enter.stop="add">
            <div class="modal-wr">
                <div class="modal-container">

                    <div class="modal-header">
                        <p name="header">
                            Новая компания
                        </p>
                        <button type="button" class="close" @click="close"  data-dismiss="modal">&times;</button>
                    </div>

                    <div class="modal-body small">
                        <form class="form">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label col-form-label-sm">Имя компании</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control  form-control-sm" id="name" placeholder="Имя компании"
                                    v-model="company.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="type" class="col-sm-4 col-form-label col-form-label-sm">Тип компании</label>
                                <div class="col-sm-8">
                                    <select class="form-control  form-control-sm" id="type" placeholder="Тип компании"
                                    v-model="company.type"
                                    >
                                        <option value="ЮЛ">
                                            ЮЛ
                                        </option>
                                        <option value="ФЛ">
                                            ФЛ
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <form class="form" v-if="company.type == 'ЮЛ'">
                            <div class="form-group row">
                                <label for="inn" class="col-sm-4 col-form-label col-form-label-sm">ИНН</label>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control  form-control-sm" id="inn" placeholder="ИНН"
                                    v-model="company.inn"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="kpp" class="col-sm-4 col-form-label col-form-label-sm">КПП</label>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control  form-control-sm" id="kpp" placeholder="КПП"
                                    v-model="company.kpp"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ogrn" class="col-sm-4 col-form-label col-form-label-sm">ОГРН</label>
                                <div class="col-sm-8">
                                    <input type="number" class="form-control  form-control-sm" id="ogrn" placeholder="ОГРН"
                                    v-model="company.ogrn"
                                    />
                                </div>
                            </div>
                        </form>
                        <form class="form" v-if="company.type == 'ФЛ'">
                            <div class="form-group row">
                                <label for="passport" class="col-sm-4 col-form-label col-form-label-sm">Паспорт</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control  form-control-sm" id="passport" placeholder="Паспорт"
                                    v-model="company.passport"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <div class="modal-footer text-center" v-if="company.type">
                        <button class="btn btn-outline-dark" @click="add">Добавить</button>
                        <button class="btn btn-outline-dark" @click="close">Отменить</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: ['soft'],
    data(){
        return{
            company: {}
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },
        add(){
            if(!this.company.name || this.company.name == ''){
                this.soft.Err(true, 'err', 'Не заполнено название компании');
                return;
            }
            if(this.company.type == 'ЮЛ'){
                if(!this.company.inn || this.company.inn == ''){
                    this.soft.Err(true, 'err', 'Не заполнен ИНН');
                    return;
                }
            }else{
                if(!this.company.passport || this.company.passport == ''){
                    this.soft.Err(true, 'err', 'Не заполнены паспортные данные')
                    return;
                }
            }
            var data = {act: 'save', base: 'companys', query: this.company}
            this.soft.send(data);
            this.close();
        }
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 80px;
    margin-bottom: 110px;
}

.modal-container {
width: 500px;
overflow: hidden;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.sticky-bottom {
    position: relative;
    bottom: 0px;
}
</style>
