// <CatalogInput :group="'61c03466b245ca06ce0003dc'" :field_number="1" :full="false" :soft="soft" v-model:val="service.service"></CatalogInput>
// group - id группы в справочнике
// field_number - номер поля 
// full - вернуть весь объект или только значение поля


<template lang="html">
    <div class="form-group row">
        <label for="basis" class="col-sm-4" v-if="label">{{ label }}</label>
        <div :class="{'col-sm-8': label, 'col-12': !label}">
            <span v-if="disabled">{{ choose_item }}</span>
            <div v-else class="form-control form-control-sm pointer" @click="show_modal = true">
                <div class="row ">
                    <div class="val pl-3 pr-1 text-truncate">
                        {{ choose_item }}
                    </div>
                    <div class="oix pr-1">
                        <span v-if="!disabled && val != ''" class="oi oi-x float-right" @click.stop="clear()"></span>
                    </div>
                </div>
            </div>
        </div>
        <CatalogModal v-if="show_modal" @close="show_modal = false" @choose="getItem" :label="label" :group="group" :soft="soft" ></CatalogModal>
    </div>
</template>

<script>
import CatalogModal from './CatalogModal.vue';

export default {
    props: ['label', 'group', 'soft', 'field_number', 'full', 'val', 'disabled'],
    data(){
        return {
            show_modal: false
        }
    },
    components: {
        CatalogModal
    },
    emits: ['update:val'],
    methods: {
        getItem(item){
            if(this.full){
                this.$emit('update:val', item);
            }else{
                console.log(item.values[this.field_number].value);
                var ans = item.values[this.field_number].value
                this.$emit('update:val', ans);
            }
        },
        clear(){
            this.$emit('update:val', '');
        }
    },
    computed: {
        choose_item: {
            get: function(){
                if(this.val){
                    if(this.full){
                        return this.val.values[this.field_number].value;
                    }else{
                        return this.val
                    }
                }else{
                    return '';
                }
                
            }
        }
    },
    mounted(){

    }
}
</script>

<style lang="css" scoped>
.form-control {
    font-size: 0.8rem;
}

.val {
    width: calc(100% - 25px);
}

.oix {
    width: 15px;
}

.oi-x {
    margin-top: 2px;
}

</style>
