<template lang="html">
    <div class="form mb-2">
        <div class="row">
            <div :class="{'col-sm-8': !full, 'col-12': full}">
                <input class="form-control form-control-sm" type="text" :value="search_string" @input="search" @focus="show_result=true" @blur="hideMenu">
                <span class="oi oi-x clear" @click="clear()" v-if="search_string != ''"></span>
            </div>
        </div>
        <div class="row" v-show="show_result">
            <div class="col-sm-8">
                <ul class="list-group fixed result">
                    <li class="list-group-item" v-for="item, key in soft.getAll(base)" @click="choseItem(item)" :key="key">{{ item[field] }}</li>
                    <!-- <li class="list-group-item">Создать</li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['soft', 'base', 'field', 'val', 'full'],
    data(){
        return {
            search_string: '',
            search_timer: {},
            show_result: false
        }
    },
    emits: ['update:val'],
    methods: {
        search(event){
            if(event.currentTarget.value != this.search_string && event.currentTarget.value != ""){
                clearTimeout(this.search_timer);
                this.search_string = event.currentTarget.value;
                this.search_timer = setTimeout(()=>{
                    this.soft.send({act: 'show', base: this.base, search: this.search_string})
                }, 1000)
            }
        },
        hideMenu(){
            setTimeout(()=>{
                this.show_result = false;
            }, 200)
        },
        choseItem(item){
            this.search_string = item[this.field]
            this.$emit('update:val', item);
        },
        clear(){
            this.search_string = '';
            this.$emit('update:val', '');
        }
    },
    mounted(){
        if(this.val){
            this.search_string = this.val[this.field];
        }
    }
}
</script>

<style lang="css" scoped>
.fixed {
    z-index: 999;
    position: fixed;
    margin-top: 2px;
}

.list-group-item {
    cursor: pointer;
    font-size: 10pt;
}

.result {
    max-height: 400px;
    overflow-y: scroll;
}

.clear {
    font-size: 14px;
    position: absolute;
    right: 24px;
    top: 6px;


}
</style>
