<template lang="html">
    <transition name="modal">
        <div id="modal-mask" class="modal-m" @click.self="close">
            <div class="modal-wr">
              <div class="modal-container">

                <div class="modal-header">
                  <slot name="header">
                     Добавить платеж:
                  </slot>
                  <button type="button" class="close" @click="close"  data-dismiss="modal">&times;</button>
                </div>

                <div class="modal-body small">
                    <div class="row">
                        <div class="col">
                            <label for="">Дата платежа</label>
                            <input type="date" class="form-control form-control-sm" v-model="payDate">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <label for="">Сумма платежа</label>
                            <input type="number" min="1" class="form-control form-control-sm" v-model.number="amount">
                        </div>
                    </div>


                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-dark" @click="add()">Добавить</button>
                </div>
              </div>
            </div>
        </div>
    </transition>
</template>

<script>


export default {
    props: ['soft'],
    data(){
        return{
            amount: '',
            payDate: ''
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },
        add(){
            if(new Date(this.payDate) > 4127760000000 || this.payDate == ''){
                this.soft.Err(true, 'err', 'Не корректная дата платежа');
            }else if(this.amount < 1 || this.amount == ''){
                this.soft.Err(true, 'err', 'Не корректная сумма платежа');
            }else{
                this.$emit('add', {
                    amount: this.amount,
                    date: this.payDate,
                })
                this.close();
            }
            
        }
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 180px;
    margin-bottom: 110px;
}

.modal-container {
width: 500px;
max-height: 90vh;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}
</style>
