<template lang="html">
    <transition name="modal">
        <div id="modal-mask" class="modal-m" @click.self="close" @enter.stop="add">
            <div class="modal-wr">
                <div class="modal-container">

                    <div class="modal-header">
                        <div name="header" class="header">
                            {{ task.name }}
                            <p>{{ soft.nDate(task.createDate) }}</p>
                        </div>
                        <button type="button" class="close" @click="close"  data-dismiss="modal">&times;</button>
                    </div>

                    <div class="modal-body small">
                        <div class="">
                            {{ task.text }}
                        </div>
                        
                        <div>
                            <div class="card-body p-1">
                                <div class="row" v-for="comment in task.comments" :key="comment._id">
                                    <div class="comment col-11">
                                        <div class="comment-header">
                                            {{ comment.user.username}} - {{ soft.nDate(comment.createDate) }}
                                            <span class="oi oi-trash float-right" @click="removeComment(key)" v-if="comment.user.login == soft.user().login"></span>
                                        </div>

                                        <div class="comment-body">
                                            {{ comment.text }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-0" v-if="show_comment">
                                <p class="info text-danger" v-if="empty_comment">Не заполнен комментарий</p>
                                <form class="form">
                                    <div class="form-group col-12">
                                        <label for="msg">Введите сообщение:</label>
                                        <textarea class="form-control" :class="{'is-invalid': empty_comment}" id="comment" rows="2" v-model="comment" @input="empty_comment=false"></textarea>
                                    </div>
                                </form>
                                <button class="btn btn-outline-dark btn-sm ml-3" @click="addComment">Добавить</button>
                                <button class="btn btn-outline-dark btn-sm ml-3" @click="show_comment = false">Отменить</button>
                            </div>
                            <div class="m-0" v-else>
                                <span class="oi oi-plus"  v-if="!task.completed" @click="show_comment = true; empty_comment = false"></span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="modal-footer">
                        <button class="btn btn-outline-dark" v-if="!task.completed" @click="complete">Выполнить</button>
                        <button class="btn btn-outline-dark" v-if="!task.completed" @click="save">Сохранить</button>
                        <button class="btn btn-outline-dark" @click="close">Закрыть</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
// import CatalogInput from '../../Global/Catalog/CatalogInput.vue';
// import Input from '../../Global/Input/Input.vue';

export default {
    props: ['soft', 'id'],
    data(){
        return{
            task: this.soft.get('tasks', this.id),
            show_comment: false,
            empty_comment: false,
            comment: ''
        }
    },
    components: {
        // Input,
        // CatalogInput
    },
    methods: {
        close(){
            this.$emit('close');
        },
        save(){
            var data = {act: 'save', base: 'tasks', query: this.task}
            this.soft.send(data);
            this.close();
        },
        complete(){
            this.task.completed = true;
            this.save();
        },
        addComment(){
            if(this.comment == ''){
                this.empty_comment = true;
            }else{
                if(!this.task.comments){
                    this.task.comments = {}
                }
                this.task.comments.push(
                    {
                        createDate: new Date(),
                        user: this.soft.user(),
                        text: this.comment
                    }
                );
                this.comment = '';
                this.show_comment = false;
            }
        },
        removeComment(key){
            this.task.comments.splice(key, 1);
        }
    },
    computed:{
        
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 80px;
    margin-bottom: 110px;
}

.modal-container {
width: 600px;
overflow: hidden;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.header {
    font-weight: bold;
    margin-bottom: 0px;
}

.header p {
    font-weight: normal;
    font-size: 0.8rem;
    opacity: 0.5;
    margin-bottom: 0;
    margin-top: 6px;
    padding-bottom: 0;
}

.sticky-bottom {
    position: relative;
    bottom: 0px;
}


.comment {
    border: 1px solid #a2aab8;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 20px;
    margin-left: 10px;
}

.comment:hover {
    border-color: #383838;
}

.comment:hover .comment-header{
    border-bottom-color: #383838;
    color:#383838;
}

.comment-header {
    font-size: 8pt;
    border-bottom: 1px solid #a2aab8;
    padding-left: 4px;
    color: #626670;
}

.comment:hover .comment-header{
    border-bottom-color: #383838;
}

.comment-body {
    font-size: 10pt;
    margin-top: 6px;
    padding-left: 10px;
}
</style>
