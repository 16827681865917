<template lang="html">
    <transition name="modal">
        <div id="modal-mask" class="modal-m" @click.self="close" @enter.stop="add">
            <div class="modal-wr">
                <div class="modal-container">

                    <div class="modal-header">
                        <p name="header" class="header">
                            Новая задача
                        </p>
                        <button type="button" class="close" @click="close"  data-dismiss="modal">&times;</button>
                    </div>

                    <div class="modal-body small">
                        <div class="row">
                            <label for="name">Название задачи:</label>
                            <input class="form-control form-control-sm" type="text" id="name" v-model="task.name">
                        </div>
                        <div class="row mt-2">
                            <label for="text">Текст задачи:</label>
                            <textarea class="form-control form-control-sm" rows="4" id="text" v-model="task.text">
                            </textarea>
                        </div>
                    </div>
                    
                    <div class="modal-footer text-center">
                        <button class="btn btn-outline-dark" @click="add">Добавить</button>
                        <button class="btn btn-outline-dark" @click="close">Отменить</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
// import CatalogInput from '../../Global/Catalog/CatalogInput.vue';
// import Input from '../../Global/Input/Input.vue';

export default {
    props: ['soft'],
    data(){
        return{
            task: {
                createDate: Date.now(),
                completed: false
            }
        }
    },
    components: {
        // Input,
        // CatalogInput
    },
    methods: {
        close(){
            this.$emit('close');
        },
        add(){
            var data = {act: 'save', base: 'tasks', query: this.task}
            this.soft.send(data);
            this.close();
        }
    },
    computed:{
        
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 80px;
    margin-bottom: 110px;
}

.modal-container {
width: 600px;
overflow: hidden;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.header {
    font-weight: bold;
    margin-bottom: 15px;
}

.sticky-bottom {
    position: relative;
    bottom: 0px;
}

.total {
    font-size: 16px;
    font-weight: bold;
}
</style>
