<template lang="html">
    <transition name="modal">
        <div id="modal-mask" class="modal-m" @click.self="close" @enter.stop="add">
            <div class="modal-wr">
                <div class="modal-container">

                    <div class="modal-header">
                        <p name="header" class="header">
                            Новый счет
                        </p>
                        <button type="button" class="close" @click="close"  data-dismiss="modal">&times;</button>
                    </div>

                    <div class="modal-body small">
                        <p class="header">Реквизиты:</p>
                        <form class="form col-8">
                            <div class="form-group row">
                                <label for="dateFrom" class="col-sm-4 col-form-label col-form-label-sm">Дата выставления</label>
                                <div class="col-sm-8">
                                    <input type="date" class="form-control form-control-sm" id="dateFrom" v-model="bill.dateFrom" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="company" class="col-sm-4 col-form-label col-form-label-sm">Исполнитель</label>
                                <div class="col-sm-8">
                                    <Input id="company" :full="true" :soft="soft" :base="'companys'" :field="'name'" v-model:val="bill.company"></Input>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="partner" class="col-sm-4 col-form-label col-form-label-sm">Заказчик</label>
                                <div class="col-sm-8">
                                    <Input id="partner" :full="true" :soft="soft" :base="'partners'" :field="'name'" v-model:val="bill.partner"></Input>
                                </div>
                            </div>
                        </form>
                        <p class="header">Продукты и услуги:</p>
                        <div class="row mb-2 border-bottom">
                            <div class="col-1 text-center">
                                №
                            </div>
                            <div class="col-6 text-center">
                                Наименование
                            </div>
                            <div class="col-1">
                                Кол-во
                            </div>
                            <div class="col-1">
                                Ед.
                            </div>
                            <div class="col-1 pl-0">
                                Стоимость
                            </div>
                            <div class="col-1 pl-1">
                                Сумма
                            </div>
                            <div class="col-1">
                                
                            </div>
                        </div>
                        <div class="row input-group" v-for="service, key in bill.services" :key="key">
                            <div class="col-1 text-center pt-1">
                                {{ key+1 }}
                            </div>
                            <div class="col-6">
                                <CatalogInput :group="'61c03466b245ca06ce0003dc'" :soft="soft" :field_number="0" :full="false" v-model:val="service.service"></CatalogInput>
                            </div>
                            <div class="col-1 pr-0">
                                <input type="number" placeholder="Количтество" class="form-control form-control-sm"  v-model="service.sum">
                            </div>
                            <div class="col-1">
                                <CatalogInput :group="'61c03466b245ca06ce0003dc'" :soft="soft" :field_number="1" :full="false" v-model:val="service.unit"></CatalogInput>
                            </div>
                            <div class="col-1 pr-0">
                                <input type="number" placeholder="Стоимость" class="form-control form-control-sm"  v-model="service.price">
                            </div>
                            <div class="col-1 text-center pt-1">
                                {{ service.sum*service.price }}
                            </div>
                            <div class="col-1 text-center pt-1">
                                <span class="oi oi-trash " @click="removeService(key)"></span>
                            </div>
                        </div>
                        <span class="oi oi-plus mt-0 ml-3" @click="addService()"></span>
                        <div class="row border-top mt-3 pt-2" v-if="bill.services.length > 0">
                            <div class="col-7">
                            </div>
                            <div class="col-3 pr-4 text-right total">
                                Итого:
                            </div>
                            <div class="col-2 pl-1 total">
                                {{ total }} р.
                            </div>
                            <div class="col-1">
                                
                            </div>
                        </div>
                    </div>
                    
                    <div class="modal-footer text-center">
                        <button class="btn btn-outline-dark" @click="add">Выставить</button>
                        <button class="btn btn-outline-dark" @click="close">Отменить</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import CatalogInput from '../../Global/Catalog/CatalogInput.vue';
import Input from '../../Global/Input/Input.vue';

export default {
    props: ['soft'],
    data(){
        return{
            bill: {
                services: []
            }
        }
    },
    components: {
        Input,
        CatalogInput
    },
    methods: {
        close(){
            this.$emit('close');
        },
        addService(){
            this.bill.services.push({
                service: '',
                sum: 0,
                price: 0
            })
        },
        removeService(key){
            this.bill.services.splice(key, 1)
        },
        add(){
            console.log(this.bill.company);
            var data = {act: 'save', base: 'bills', query: this.bill}
            this.soft.send(data);
            this.close();
        }
    },
    computed:{
        total: {
            get: function(){
                let total = 0;
                for (const service of this.bill.services) {
                    total = total+service.price*service.sum;
                }
                return total;
            }
        }
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 80px;
    margin-bottom: 110px;
}

.modal-container {
width: 80vw;
max-height: calc(100vh - 160px);
overflow: hidden;
overflow-y: scroll;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.header {
    font-weight: bold;
    margin-bottom: 15px;
}

.sticky-bottom {
    position: relative;
    bottom: 0px;
}

.total {
    font-size: 16px;
    font-weight: bold;
}
</style>
