<template lang="html">
    <div class="block">
        <div>
            <div class="row  mt-2 ml-1">
                <div class="btn btn-outline-dark mr-2" @click="save">
                    Сохранить
                </div>
                <div class="btn btn-outline-dark" @click="close">
                    Закрыть
                </div>
            </div>
            <div class="col-4">
                <div class="card" v-if="!edit_main_data">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-12">
                                <span class="oi oi-pencil float-right" @click="edit_main_data = true"></span>
                            </div>
                        </div>
                        <h6 class="card-title mb-1">{{ company.name }}</h6>
                        <div class="row">
                            <div v-if="company.type == 'ЮЛ'" class="info ml-3">
                                ИНН: {{ company.inn }} КПП: {{ company.kpp }}
                            </div>
                            <div v-if="company.type == 'ФЛ'" class="info ml-3">
                                Паспорт: {{ company.passport }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="edit_main_data">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-12">
                                <span class="oi oi-check float-right" @click="edit_main_data = false"></span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="name" class="col-sm-4 col-form-label col-form-label-sm">Имя компании</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="name" placeholder="Имя компании"
                                v-model="company.name"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="fullName" class="col-sm-4 col-form-label col-form-label-sm">Полное наименование</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="fullName" placeholder="Полное наименование"
                                v-model="company.fullName"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inn" class="col-sm-4 col-form-label col-form-label-sm">ИНН</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="inn" placeholder="ИНН"
                                v-model="company.inn"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="kpp" class="col-sm-4 col-form-label col-form-label-sm">КПП</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="kpp" placeholder="КПП"
                                v-model="company.kpp"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="ogrn" class="col-sm-4 col-form-label col-form-label-sm">ОГРН</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="ogrn" placeholder="ОГРН"
                                v-model="company.ogrn"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="director" class="col-sm-4 col-form-label col-form-label-sm">Руководитель</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="director" placeholder="Руководитель"
                                v-model="company.director"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="position" class="col-sm-4 col-form-label col-form-label-sm">Должность</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="position" placeholder="Должность"
                                v-model="company.position"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="basis" class="col-sm-4 col-form-label col-form-label-sm">Основание</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="basis" placeholder="Основание"
                                v-model="company.basis"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="passport" class="col-sm-4 col-form-label col-form-label-sm">Паспорт</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control  form-control-sm" id="passport" placeholder="Паспорт"
                                v-model="company.passport"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title mb-1">Контакты:</h6>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="contact, key in company.contacts" :key="key" @click="editItem(key, 'contacts')">
                                <span class="oi oi-trash float-right" @click.stop="removeItem(key, 'contacts')"></span>
                                <p class="info">{{ contact.name }}</p>
                                <p class="info">{{ contact.position }} {{ contact.fio }}</p>
                                <p class="info">{{ contact.phone }}</p>
                                <p class="info">{{ contact.email }}</p>
                            </li>
                        </ul>
                        <span class="oi oi-plus mt-2 ml-2" @click="editItem('add', 'contacts')"></span>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title mb-1">Адреса:</h6>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="address, key in company.addresses" :key="key" @click="editItem(key, 'addresses')">
                                <span class="oi oi-trash float-right" @click.stop="removeItem(key, 'addresses')"></span>
                                <p class="info mt-2">{{ address.type.values[0].value }}:</p>
                                <p class="info">{{ address.val }}</p>
                            </li>
                        </ul>
                        <span class="oi oi-plus mt-2 ml-2" @click="editItem('add', 'addresses')"></span>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title mb-1">Банковские счета:</h6>
                        <ul class="list-group">
                            <li class="list-group-item" v-for="ac, key in company.acs" :key="key" @click="editItem(key, 'acs')">
                                <span class="oi oi-trash float-right" @click.stop="removeItem(key, 'acs')"></span>
                                <p class="info">{{ ac.name }}</p>
                                <p class="info">Счет: {{ ac.number }}</p>
                                <p class="info">Кор. счет: {{ ac.number }}</p>
                                <p class="info">БИК: {{ ac.bik }}</p>
                            </li>
                        </ul>
                        <span class="oi oi-plus mt-2 ml-2" @click="editItem('add', 'acs')"></span>
                    </div>
                </div>
            </div>
            <div class="col-6">

            </div>
        </div>
        <component v-if="show_item" v-bind:is="show_item" :data="item" :item_id="item_id" :soft="soft"  @close="show_item = false" @save="saveItem"></component>
    </div>
</template>

<script>

import Contact from './Contact.vue';
import AC from './AC.vue';
import Address from './Address.vue';

export default {
    props: ['soft', 'id'],
    data(){
        return {
            company: this.soft.get('companys', this.id),
            item: {},
            item_id: '',
            show_item: false,
            edit_main_data: false
        }
    },
    components: {
        'contacts': Contact,
        'acs': AC,
        'addresses': Address
    },
    methods: {
        close(){
            this.$emit('close');
        },
        save(){
            this.soft.send({act: 'save', base: 'companys', query: this.company})
        },
        removeItem(id, base){
            this.company[base].splice(id, 1);
        },
        editItem(id, base){
            if(id == 'add'){
                this.item = {}
                this.item_id = id;
                this.show_item = base;
            }else{
                this.item = this.company[base][id];
                this.item_id = id;
                this.show_item = base;
            }
        },
        saveItem(data){
            if(data.item_id == 'add'){
                this.company[this.show_item].push(data.item);
            }else{
                this.company[this.show_item].splice(this.item_id, 1, data.item)
            }
            this.show_item = false;
        }
    },
    mounted(){
    }
}
</script>

<style type="text/css" scoped>
.info {
    font-size: 0.8rem;
}

p.info {
    margin: 0;
}

.oi {
    font-size: 0.8rem;
}
</style>