<template lang="html">
    <div class="block">
        <div class="card">
            <div class="card-header">
                Справочник {{ catalog._id }}
            </div>
            <div class="card-body">
                <form>
                    <div class="form-group row">
                        <label for="name" class="col-sm-3 col-form-label">Название справочника:</label>
                        <div class="col-sm-8">
                            <input id="name" type="text" class="form-control" v-model="catalog.name">
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-header">
                Поля справочника
            </div>
            <div class="card-body">
                <form>
                    <div class="form-group row" v-for="field, key in catalog.fields">
                        <label for="name" class="col-sm-3 col-form-label">Название поля:</label>
                        <div class="col-sm-8">
                            <input id="name" type="text" class="form-control" v-model="field.name">
                        </div>
                        <span class="oi oi-trash" @click="removeField(key)"></span>
                    </div>
                </form>
                <span class="oi oi-plus ml-1" @click="addField()"></span>
            </div>
            <div class="card-footer">
                <button class="btn btn-outline-dark ml-1" @click="save()">Сохранить</button>
                <button class="btn btn-outline-dark ml-1" @click="close()">Заккрыть</button>
                <button class="btn btn-outline-danger float-right" v-if="catalog._id" @click="remove()">Удалить</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['catalog'],
    data(){
        return {

        }
    },
    methods: {
        addField(){
            this.catalog.fields.push({
                name: ''
            })
        },
        removeField(key){
            this.catalog.fields.splice(key, 1);
        },
        save(){
            this.$emit('save');
        },
        close(){
            this.$emit('close');
        },
        remove(){
            this.$emit('remove');
        }
    }
}
</script>

<style lang="css" scoped>
.oi {
    color: #6c757d;
    cursor: pointer;
}

.oi:hover {
    color: #383838;
}

.oi-plus, .oi-check {
    border: 1px solid #6c757d;
    border-radius: 50%;
    padding: 4px;
}

.oi-plus:hover, .oi-check:hover {
    border-color: #383838;
}

</style>
