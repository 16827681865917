<template>
  <div>
    <div class="loginForm card">
      <div class="card-body">
        <div class="text-center">
          <img src="../assets/imgs/logo.png">
        </div>
        <form class="form-horizontal" @keypress.enter="auth">
          <div class="form-group">
            <label for="login" class="control-label">Логин: </label>
            <input type="text" name="login" v-model="lgn" id="login" class="form-control">
            <label for="login" class="control-label">Пароль: </label>
            <input type="password" name="password" v-model="pswd" id="password" class="form-control">
            <div class="text-center col-12">
              <button v-if="!send || err != ''" type="button" name="button" @click="auth" class="btn btn-outline-dark col-12">Вход</button>
            </div>
            <p class="error" v-if="err.flag && err.type == 'login'">{{ err.errMsg }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script lang="">
export default {
    props: ['err'],
    data () { return {
      lgn: '',
      pswd: '',
      send: false
    } },
    methods: {
      auth(){
          this.send = true;
          this.$emit('login', {login: this.lgn, password: this.pswd});
      }
    }
  }
</script>

<style scoped>

.loginForm {
  width: 300px;
  margin: 0 auto;
  margin-top: 200px;
}

.btn {
  margin-top: 20px;
}

.error {
    margin-top: 20px;
    color: #F65153;
}

</style>
