<template lang="html">
  <div class="block">
      <div v-if="!show_modal">
          <button class="btn btn-outline-dark mb-2" @click="createItem()" :disabled="show_modal">Добавить</button>
          <ul class="list-group list-group-sm">
              <li class="list-group-item" v-for="item, key in bases.catalog" @click="selectItem(item)" :key="key">
                  <span v-for="field, key in item.values" :key="key">{{field.name}} - {{ field.value }} | </span>
                  <span class="oi oi-trash float-right " @click.stop="removeItem(item)"></span>
              </li>
          </ul>
      </div>

      <catalogModal v-else :item="selected_item" @save="save()" @close="show_modal=false">
      </catalogModal>
  </div>
</template>

<script>
import catalogModal from './modal/catalogModal.vue';

export default {
    props: ['soft', 'group', 'bases'],
    data(){
        return {
            selected_item: '',
            show_modal: false
        }
    },
    components: {
        catalogModal
    },
    methods: {
        createItem(){
            this.selected_item = {
                groupID: this.group._id,
                values: []
            };
            for (var field of this.group.fields) {
                this.selected_item.values.push({
                    name: field.name,
                    value: ''
                });
            }
            this.show_modal = true;
        },
        save(){
            var data = {act: 'save', base: 'catalog', query: this.selected_item}
            this.soft.send(data);
        },
        removeItem(id){
            var data = {act: 'remove', base: 'catalog', id: id}
            this.soft.send(data);
        },
        selectItem(item){
            this.selected_item = item;
            this.show_modal = true;
        }
    },
    mounted(){
        
    }
}
</script>

<style lang="css" scoped>
</style>
