<template>
    <div class="block">
        <div>
            <div class="row">
                <div class="col-8">
                    <input type="text" class="form-control mb-1" name="search" placeholder="Поиск" v-model="search_string" @input="search">
                </div>
                <div class="col-2">
                    <button type="button" name="button" class="btn btn-outline-dark" @click="add">Добавить</button>
                </div>
            </div>
            <div class="row mt-2 justify-content-end">
                <div class="col-2">
                    <div class="btn-group" role="group" v-if="checked_bills.length > 0">
                        <button id="printBtn" type="button" class="btn btn-sm btn-outline-dark dropdown-toggle float-right" data-toggle="dropdown" aria-haspopup="true">
                        Действия
                        </button>
                        <div class="dropdown-menu" aria-labelledby="printBtn">
                            <a class="dropdown-item text-dark bg-light" @click="print('bill')">Печать</a>
                            <a class="dropdown-item text-dark bg-light" @click="remove()">Удалить</a>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                </div>
            </div>
            <div class="row">
                <div class="col-8 mt-3">
                    <table class="table table-sm text-center">
                        <thead>
                            <tr>
                                <th >Номер</th>
                                <th class="text-left">Компания</th>
                                <th class="text-left" >Контрагент</th>
                                <th >Начислено</th>
                                <th >Оплачено</th>
                                <th >Дата</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="bill, key in soft.getAll('bills')"
                            :id="key"
                            :key="bill._id"
                            @click="show(bill._id)"
                            :class="{'done':totalPrice(bill) == totalPayment(bill)}"
                            >
                                <td>{{ bill.number }}</td>
                                <td class="text-left">{{ bill.company.name }}</td>
                                <td class="text-left">{{ bill.partner.name }}</td>
                                <td>{{ totalPrice(bill) }}</td>
                                <td>{{ totalPayment(bill) }}</td>
                                <td>{{ soft.nDate(bill.dateFrom) }}</td>
                                <td>
                                    <input type="checkbox" :id="bill._id+key" @click.stop v-model="checked_bills" :value="bill._id">
                                    <label :for="bill._id+key" @click.stop><span></span></label>
                                    <!-- <span class="oi oi-trash" @click.stop="" v-if="soft.right('admin')"></span> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <appModal v-if="show_detail" :soft="soft" :id="active_bill" @close="close">
        </appModal>
        
        <addBill v-if="add_bill" :soft="soft" @close="add_bill = false">
        </addBill>
    </div>
</template>

<script>
import appModal from './modals/Bill.vue';
import addBill from './modals/addBill.vue';

export default {
    props: ['soft'],
    data(){
        return{
            search_string: '',
            timer_string: '',
            search_timer: {},
            show_detail: false,
            add_bill: false,
            active_bill: '',
            checked_bills: []
        }
    },
    components: {
        appModal,
        addBill
    },
    methods: {
        add(){
            this.add_bill = true;
        },
        show(id){
            this.active_bill = id;
            this.show_detail = true;
        },
        search(){
            if(this.search_string != this.timer_string){
                clearTimeout(this.search_timer);
                this.timer_string = this.search_string;
                this.search_timer = setTimeout(()=>{
                    this.soft.send({act: 'show', base: 'bills', search: this.search_string})
                }, 1000)
            }
        },
        close(){
            this.show_detail = false;
        },
        remove(){
            for (const id of this.checked_bills) {
                var data = {act: 'remove', base: 'bills', id}
                this.soft.send(data);
            }
        },
        print(template){
            this.soft.send({act: 'print', base: 'printer', items: this.checked_bills, template})
        },
        totalPrice(bill){
            let total = 0;
            for (const service of bill.services) {
                total = total+service.price*service.sum;
            }
            return total;
        },
        totalPayment(bill){
            let total = 0;
            for (const payment of bill.payments) {
                total = total+payment.amount;
            }
            return total;
        }
    },
    mounted(){
        var data = {act: 'show', base: 'bills'}
        this.soft.send(data);
    }
}
</script>


<style type="text/css" scoped>
.table {
    font-size: 0.8rem;
    cursor: pointer;
}

.done {
    background-color: rgb(241 246 253);
}
</style>