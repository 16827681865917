<template lang="html">
    <div class="mask">
        <div class="errBlock" :class="errClass">
            <span v-if="this.err.type != 'loading'">{{ err.errMsg }}</span>
            <img v-else src="./Infinity.gif" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: ['err'],
    computed: {
        errClass: function(){

            var classes = {
                error: false,
                loading: false
            }

            switch(this.err.type){
                case 'errConnection':
                    classes.error = true;
                break;

                case 'loading':
                    classes.loading = true;
                break;

                case 'err':
                    classes.error = true;
                    setTimeout(()=>{this.err.flag = false;}, 1000);
                break;
            }

            return {
                'error': classes.error,
                'loading': classes.loading
            };
        }
    }
}
</script>

<style lang="css" scoped>
.mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.errBlock {
    position: relative;
    font-size: 18pt;
    margin: 0 auto;
    margin-top: 300px;
    padding: 6px;
    left: -50px;
    text-align: center;
    z-index: 9999;
    border: 0px;
    border-radius: 8px;
    background-color: white;
}

.error {
    width: 800px;
    color: red;
}

/* .loading {
    width: 200px;
    color: #0A8FE6;
} */

/* img {
    width: 600px;
} */
</style>
