<template lang="html">
  <div class="block">
      <div v-if="main_show">
          <button type="button" class="btn btn-outline-dark ml-2 mb-2" @click="add">Добавить</button>
          <div class="list-group">
              <li v-for="usr in soft.getAll('users')" class="list-group-item pointer" :key="usr._id" @click="show(usr._id)">
                  {{ usr.username }}
                  <span class="oi oi-trash float-right " @click.stop="remove" :id="usr._id"></span>
              </li>
          </div>
      </div>
      <appModal v-if="user_show" :soft="soft" :id="active_user" @save="save" @close="close">
      </appModal>
  </div>
</template>

<script>
import appModal from './modals/user.vue';

export default {
    props: ['soft'],
    data(){
        return{
            main_show: true,
            user_show: false,
            active_user: '',
            lable: 'Пользователь',
            attribute: {
                login: 'Логин',
                password: 'Пароль',
                username: 'ФИО'
            }
        }
    },
    components: {
        appModal
    },
    methods: {
        add(){
            this.active_user = {};
            this.user_show = true;
            this.main_show = false;
        },
        show(id){
            this.active_user = id;
            this.user_show = true;
            this.main_show = false;
        },
        save(){
            var data = {act: 'save', base: 'users', query: this.active_user, id: this.active_user._id}
            this.soft.send(data);
            this.close();
        },
        close(){
            this.user_show = false;
            this.main_show = true;
        },
        remove(event){
            var data = {act: 'remove', base: 'users', id: event.target.id}
            this.soft.send(data);
        }
    },
    mounted(){
        var data = {act: 'show', base: 'users'}
        this.soft.send(data);
    }
}
</script>

<style lang="css">
.oi-trash {
    cursor: pointer;
}
</style>
