<template lang="html">
    <transition name="modal">
        <div id="modal-mask" class="modal-m" @click.self="close">
            <div class="modal-wr">
                <div class="modal-container">

                    <div class="modal-header">
                        <p name="header">
                            Контакт
                        </p>
                        <button type="button" class="close" @click="close"  data-dismiss="modal">&times;</button>
                    </div>

                    <div class="modal-body small">
                        <form class="form">
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label col-form-label-sm">Название</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control  form-control-sm" id="name" placeholder="Название"
                                    v-model="item.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="fio" class="col-sm-4 col-form-label col-form-label-sm">ФИО</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control  form-control-sm" id="fio" placeholder="ФИО"
                                    v-model="item.fio"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="position" class="col-sm-4 col-form-label col-form-label-sm">Должность</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control  form-control-sm" id="position" placeholder="Должность"
                                    v-model="item.position"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="phone" class="col-sm-4 col-form-label col-form-label-sm">Телефон</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control  form-control-sm" id="phone" placeholder="+7(123)456-78-90"
                                    v-model="item.phone"
                                    />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="email" class="col-sm-4 col-form-label col-form-label-sm">Email</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control  form-control-sm" id="email" placeholder="example@mail.com"
                                    v-model="item.email"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <div class="modal-footer text-center">
                        <button class="btn btn-outline-dark" v-if="item_id == 'add'" @click="save">Добавить</button>
                        <button class="btn btn-outline-dark" v-else @click="save">Сохранить</button>
                        <button class="btn btn-outline-dark" @click="close">Отменить</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: ['soft', 'data', 'item_id'],
    data(){
        return{
            item: this.data
        }
    },
    methods: {
        close(){
            this.$emit('close');
        },
        save(){
            this.$emit('save', {item: this.item, item_id: this.item_id});
        }
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 80px;
    margin-bottom: 110px;
}

.modal-container {
width: 500px;
overflow: hidden;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.sticky-bottom {
    position: relative;
    bottom: 0px;
}
</style>
