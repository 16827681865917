<template>
    <div class="block">
        <div class="" v-if="!show_detail">
            <div class="row">
                <div class="col-8">
                    <input type="text" class="form-control mb-1" name="search" placeholder="Поиск" v-model="search_string" @input="search">
                </div>
                <div class="col-2">
                    <button type="button" name="button" class="btn btn-outline-dark" @click="add">Добавить</button>
                </div>
            </div>
            <div class="row">
                <div class="col-10 mt-3">
                    <table class="table table-sm text-center">
                        <thead>
                            <tr>
                                <th class="text-left">Название</th>
                                <th >Тип организации</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :class="{'table-success': company.new}" v-for="company, key in soft.getAll('companys')"
                            :id="key"
                            :key="company._id"
                            @click="show(company._id)"
                            >
                                <td class="text-left">{{ company.name }}</td>
                                <td>{{ company.type }}</td>
                                <td><span class="oi oi-trash" @click.stop="remove(company._id)" v-if="soft.right('admin')"></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <appModal v-else :soft="soft" :id="active_company" @close="close">
        </appModal>
        
        <addCompany v-if="add_company" :soft="soft" @close="add_company = false">
        </addCompany>
    </div>
</template>

<script>
import appModal from './modals/Company.vue';
import addCompany from './modals/addCompany.vue';

export default {
    props: ['soft'],
    data(){
        return{
            search_string: '',
            timer_string: '',
            search_timer: {},
            show_detail: false,
            add_company: false,
            active_company: ''
        }
    },
    components: {
        appModal,
        addCompany
    },
    methods: {
        add(){
            this.add_company = true;
        },
        show(id){
            this.active_company = id;
            this.show_detail = true;
        },
        search(){
            if(this.search_string != this.timer_string){
                clearTimeout(this.search_timer);
                this.timer_string = this.search_string;
                this.search_timer = setTimeout(()=>{
                    this.soft.send({act: 'show', base: 'companys', search: this.search_string})
                }, 1000)
            }
        },
        close(){
            this.show_detail = false;
        },
        remove(id){
            var data = {act: 'remove', base: 'companys', id}
            this.soft.send(data);
        }
    },
    mounted(){
        var data = {act: 'show', base: 'companys'}
        this.soft.send(data);
    }
}
</script>


<style type="text/css" scoped>
.table {
    font-size: 0.8rem;
    cursor: pointer;
}
</style>