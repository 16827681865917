<template lang="html">
  <div class="block">
      <div class="card col-6">
          <div class="card-body">
              <div class="form-group" v-for="field, key in item.values" :key="key">
                  <label>{{field.name}}</label>
                  <input class="form-control" v-model="field.value" />
              </div>
              <button class="btn btn-outline-dark" @click="save()">Сохранить</button>
              <button class="btn btn-outline-dark ml-1" @click="close()">Закрыть</button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: ['item'],
    data(){
        return {
        }
    },
    methods: {
        close(){
            this.$emit('close')
        },
        save(){
            this.$emit('save')
        }
    },
    mounted(){
    }
}
</script>

<style lang="css" scoped>
</style>
