<template lang="html">
    <div class="block">
        <div class="row no-gutters">
            <nav class="navbar navbar-expand-lg sticky-top navbar-dark col-12">
                <div>
                    <a class="navbar-brand ml-2">
                        <img src="../assets/imgs/logo.png" alt="" width="105" height="70" class="d-inline-block align-text-top">
                    </a>
                </div>
                <ul class="navbar-nav">
                    <li class="nav-item"  ><a class="nav-link" :class="{active: state == 'task'}" @click.prevent="cangeState" data-state="task" href="#">Задачи</a></li>
                    <li class="nav-item"  v-if="soft.right('manager')"><a class="nav-link" :class="{active: state == 'partner'}" @click.prevent="cangeState" data-state="partner" href="#">Контрагенты</a></li>
                    <li class="nav-item"  v-if="soft.right('manager')"><a class="nav-link" :class="{active: state == 'bill'}" @click.prevent="cangeState" data-state="bill" href="#">Счета</a></li>
                    <li class="nav-item"  v-if="soft.right('admin')"><a class="nav-link" :class="{active: state == 'company'}" @click.prevent="cangeState" data-state="company" href="#">Компании</a></li>
                    <li class="nav-item"  v-if="soft.right('admin')"><a class="nav-link" :class="{active: state == 'catalog'}" @click.prevent="cangeState" data-state="catalog" href="#">Справочники</a></li>
                    <li class="nav-item"  ><a class="nav-link" :class="{active: state == 'users'}" @click.prevent="cangeState" data-state="users" href="#">Пользователи</a></li>
                </ul>
                <div class="d-inline-flex ml-auto p-2 navbar-brand">
                    {{ user.username }}
                </div>
            </nav>
        </div>
        <div class="row no-gutters">
            <div class="col" style="z-index: 1">
                <appPartner v-if="state=='partner'" :soft="soft">
                </appPartner>
                <appCompany v-if="state=='company'" :soft="soft">
                </appCompany>
                <appBill v-if="state=='bill'" :soft="soft">
                </appBill>
                <appCatalog v-if="state=='catalog'" :bases="bases" :soft="soft">
                </appCatalog>
                <appUser v-if="state=='users'" :soft="soft">
                </appUser>
                <appTask v-if="state=='task'" :soft="soft">
                </appTask>
                <div class="emptyBlock" v-if="state == ''">
                    <img src="../assets/imgs/logo.png">
                </div>
            </div>
            <div class="block-bg"></div>
        </div>
        
        <!-- <app-checker id="check" :bases="bases" :soft="soft">
        </app-checker> -->
    </div>
</template>

<script>
import Partner from './Partners/Partners.vue';
import Company from './Companys/Companys.vue';
import Bill from './Bills/Bills.vue';
import Catalog from './Catalog/Catalogs.vue';
import User from './user/User.vue';
import Task from './Task/Tasks.vue';


export default {
    props: [
        'user',
        'socket',
        'err',
        'bases',
        'soft'
    ],
    data(){
        return {
            state: '',
        }   
    },
    components: {
        'appPartner': Partner,
        'appCompany': Company,
        'appBill': Bill,
        'appCatalog': Catalog,
        'appUser': User,
        'appTask': Task
    },
    methods:{
        cangeState(event){
            this.soft.mounted(true);
            if(this.state != event.target.dataset.state){
                this.state = '';
                this.soft.Err(true, 'loading', 'Загрузка');
            }
            this.state = event.target.dataset.state;
        }
    },
    mounted(){
        // this.soft.send({act: 'show', base: 'settings'})
    }
}
</script>

<style lang="css" >
.block {
    margin: 10px;
}

.navbar {
    margin-bottom: 10px;
    background-color: #297ded;
}

.card {
    margin-top: 10px;
}

.emptyBlock {
    width: 100%;
    margin: 0 auto;
    color: #297ded;
    font-size: 18pt;
    text-align: center;
    padding-top: 300px;
    opacity: 0.1;
}

.emptyBlock img {
    width: 180px;
}

.navbar-brand {
    cursor: pointer;
}

.mybadge {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 800;
    height: 50%;
    padding: 0 5px;
    background-color: white;
    color: #dc3545;
    border-radius: 4px;
}

.block-bg {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, #FFF 70%, #e1f1ff);
}

</style>
