<template lang="html">
  <div class="block row">
      <div class="menu border-right pr-2">
          <button class="btn btn-outline-dark mt-2 ml-3" @click="createGroup()">Добавить справочник</button>
          <ul class="list-group mt-4">
              <li class="list-group-item pointer" v-for="catalog, key in bases.catalogGroup" :key="key" @click="showGroup(catalog)">
                  <span class="oi oi-eye mr-1" v-if="selected_group == catalog"></span>
                  {{ catalog.name }}
                  <span class="oi oi-pencil float-right" @click.stop="editGroup(catalog)"></span>
              </li>
          </ul>
      </div>
      <div class="col-8 ml-3">
          <group v-if="edit_group" :catalog="selected_group" @save="saveGroup" @close="closeGroup" @remove="removeGroup">
          </group>
          <catalog v-if="edit_catalog" :bases="bases" :soft="soft" :group="selected_group" @close="closeGroup">
          </catalog>
      </div>
  </div>
</template>

<script>
import catalog from './Catalog.vue';
import group from './modal/Group.vue';

export default {
    props: ['soft', 'bases'],
    data(){
        return {
            edit_group: false,
            selected_group: '',
            edit_catalog: false,
        }
    },
    components: {
        catalog,
        group
    },
    methods: {
        createGroup(){
            this.selected_group = {
                fields: []
            };
            this.edit_group = true;
        },
        editGroup(catalog){
            this.selected_group = catalog;
            this.edit_group = true;
        },
        saveGroup(){
            var data = {act: 'saveGroup', query: this.selected_group, base: 'catalog'}
            this.soft.send(data);
        },
        removeGroup(){
            var data = {act: 'removeGroup', id: this.selected_group._id, base: 'catalog'}
            this.soft.send(data);
            this.edit_group = false;
        },
        closeGroup(){
            this.edit_group = false;
        },
        showGroup(catalog){
            this.selected_group = catalog;
            var data = {act: 'show', base: 'catalog', conditions: {groupID: catalog._id}}
            this.soft.send(data);
            this.edit_catalog = true;
        }
    },
    mounted(){
        var data = {act: 'showGroup', base: 'catalog'}
        this.soft.send(data);
    }
}
</script>

<style lang="css" scoped>

.menu {
    width: 280px;
    height: 90vh;
}
</style>
