<template lang="html">
    <transition name="modal">
        <div id="modal-mask" class="modal-m" @click.self="close">
            <div class="modal-wr">
              <div class="modal-container">

                <div class="modal-header">
                  <slot name="header">
                      {{ label }}
                  </slot>
                  <button type="button" class="close" @click="close"  data-dismiss="modal">&times;
                  </button>
                </div>

                <div class="modal-body">
                  <input type="text" class="form-control mb-1" name="search" placeholder="Введите название" v-model="query">
                  <transition-group name="search-items" tag="div">
                              <div class="list-group-item list-group-item-action"
                              v-for="item, key in computedList()"
                              :id="item._id"
                              :key="item._id"
                              :data-index="key"
                              @click="choose(item)">
                                  {{ soft.translate[item.values[0].value]||item.values[0].value }}
                              </div>
                  </transition-group>
                </div>
              </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ['label', 'group', 'soft'],
    data(){
        return {
            query: ''
        }
    },
    methods: {
        close(){
            this.soft.clearBase('catalog');
            this.$emit('close');
        },
        computedList() {
            if(typeof this.catalog === 'object'){
                return this.catalog
                // .filter(function (item) {
                //     return item.values[0].value.toLowerCase().indexOf(self.query.toLowerCase()) !== -1
                // })
                .sort((a, b)=>{
                    if(a.values[0].value > b.values[0].value) return 1
                    if(b.values[0].value > a.values[0].value) return -1
                })
            }
        },
        choose(item){
            this.soft.clearBase('catalog');
            this.$emit('choose', item);
            this.close();
        }
    },
    mounted(){
        var data = {act: 'show', base: 'catalog', conditions: {groupID: this.group}}
        this.soft.send(data);
    },
    computed: {
      catalog(){
        return this.soft.getAll('catalog');
      }
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 150px;
    margin-bottom: 110px;
}

.modal-container {
min-width: 350px;
max-width: 800px;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 2px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.list-group-item-action {
    cursor: pointer;
}

.search-items-enter-active {
  animation: fade-in .2s;
}
.search-items-leave-active {
  animation: fade-in .2s reverse;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
