<template>
    <div>
        <app-login v-if="!auth" @login="Login" :err="err">
        </app-login>
        <app-main class="container-fluid m-0 p-0" v-else
            :user="user"
            :socket="socket"
            :err="err"
            :bases="bases"
            :soft="soft"
            @logout="auth=false">
        </app-main>
        <app-loading id="print" v-if="err.flag" :err="err">
        </app-loading>
    </div>
</template>

<script>
import Main from './components/Main.vue';
import Login from './components/Login.vue';
import Loading from './components/Loading.vue';

import Translate from './soft/translate';
import nDate from './soft/nDate.js';


function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
}

// function isObject(val) {
//     if (val === null) { return false;}
//     return ( (typeof val === 'function') || (typeof val === 'object') );
// }

export default {
    data(){
      return {
          socket: '',
          err: {
              flag: false,
              type: '',
              errMsg: ''
          },
          auth: false,
          user: {},
          toomuch: false,
          bases: {
              partners: [],
              companys: [],
              bills: [],
              tasks: [],
              users: [],
              catalogGroups: [],
              catalog: [],
          },
          soft: {
                getAll: base=>{
                    return this.bases[base]
                },
                get: (base, id)=>{
                    let i = this.bases[base].findIndex((el)=>{return el._id == id});
                    if(i<0){
                        return {};
                    }else{
                        return this.bases[base][i];
                    }
                },
                user: ()=>{
                    return this.user
                },
                nDate,
                send: data=>{
                    this.soft.Err(true, 'loading', 'Загрузка');
                    data.user = this.user;
                    data = JSON.stringify(data);
                    this.socket.send(data);
                },
                print: (base, act, items, key)=>{
                    this.soft.send({base: 'printer', act: base, items, template: act, key})
                },
                changeUserSetting: (prop, value)=>{
                    this.user.settings[prop] = value;
                    var data = {act: 'update', base: 'user', id: this.user._id, query: this.user};
                    this.soft.send(data);
                },
                Err: (flag, type, msg)=>{
                    this.err.type = type;
                    this.err.errMsg = msg;
                    this.err.flag = flag;
                },
                clearBase: (base)=>{
                    this.bases[base] = []
                },
                right: this.checkRight,
                translate: Translate,
                // throttle: (callback, limit)=>{
                //     var wait = false;
                //     return function () {
                //         if (!wait) {
                //             callback.call();
                //             wait = true;
                //             setTimeout(function () {
                //                 wait = false;
                //             }, limit);
                //         }
                //     }
                // },
                goto: (state, data)=>{
                    this.mounted = false;
                    this.soft.state_data = data;
                    this.state = state;
                },
                numberWithSpaces: (x)=>{
                    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                },
                mounted: flag=>{
                    this.mounted = flag
                },
                state_data: {}
          }
      }
    },
    components: {
        'app-login': Login,
        'app-main': Main,
        'app-loading': Loading
    },
    methods: {
        onMessage(msg){
            var url = '';
            var file;
            var i;
            
            console.log('Обработка сообщения');
            switch (msg.act) {
                case 'auth':
                    console.log(msg.user);
                    this.user = msg.user;
                    this.auth = true;
                    this.error = '';
                break;

                case 'reconnected':
                    this.auth = true;
                    this.user = msg.user;
                break;

                case 'closeConnection':
                    this.auth = false;
                    this.user = {};
                    this.Connect(false);
                break;

                case 'show':
                    console.log('Получено сообщение с рузультатом '+msg.result.length);
                    if(msg.chunk == 0){
                        if(msg.result){
                            this.bases[msg.base] = [];
                            this.bases[msg.base] = msg.result;
                            console.log('write to base');
                        }
                    }else{
                        this.bases[msg.base].push(...msg.result);
                    }
                    if(this.bases[msg.base].length >= 1000){
                        this.toomuch = true;
                    }else{
                        this.toomuch = false;
                    }
                break;

                case 'success':
                    if(msg.base == 'settings' || msg.base == 'reports'){
                        this.bases[msg.base] = msg.result;
                        return;
                    }
                    if(msg.result){
                        if(Array.isArray(msg.result)){
                            for (const res of msg.result) {
                                i = this.bases[msg.base].findIndex(x => x._id === res._id);
                                if(i<0){
                                    this.bases[msg.base].unshift(res);
                                }else{
                                    this.$set(this.bases[msg.base], i, res);
                                    if(this.bases.currentObject._id == res._id){
                                        this.bases.currentObject = res
                                    }
                                }
                            }
                        }else{
                            i = this.bases[msg.base].findIndex(x => x._id === msg.result._id);
                            if(i<0){
                                this.bases[msg.base].unshift(msg.result);
                            }else{
                                this.bases[msg.base].splice(i, 1, msg.result);
                            }
                        }
                    }
                break;

                case 'removed':
                    i = this.bases[msg.base].findIndex(x => x._id === msg.result._id);
                    this.bases[msg.base].splice(i, 1);
                break;

                case 'printPDF':
                    file = new File([new Uint8Array(msg.result.data)], msg.base+'.pdf', {type: 'application/pdf'});
                    url = URL.createObjectURL(file);
                    downloadURI(url, msg.base);
                break;

                case 'print':
                    file = new File([new Uint8Array(msg.result.data)], msg.base+'.docx', {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                    url = URL.createObjectURL(file);
                    downloadURI(url, msg.base);
                break;

                case 'download':
                    file = new File([new Uint8Array(msg.result.data)], msg.name)
                    url = URL.createObjectURL(file);
                    downloadURI(url, msg.name);
                break;

                case 'err':
                    this.Err( true, 'err', msg.result);
                break;

                case 'endDownload':
                    this.Err(false)
                break;

                case 'check':
                    if(msg.result.check){
                        this.bases.messages.push(msg.result);
                    }
                break;
            }
        },
        Login(event){
            var data = {act: 'login', login: event.login, password: event.password};
            data = JSON.stringify(data);
            this.error = '';
            this.socket.send(data);
        },
        Connect(reconnect){
            var host = window.location.hostname;
            this.socket = new WebSocket("ws://"+host+":3040");
            this.socket.onopen = ()=>{
                // console.clear();
                if(reconnect){
                    this.err.flag = false;
                    var data = {act: 'reconnect', login: this.user.login};
                    data = JSON.stringify(data);
                    this.socket.send(data);
                }
                this.socket.onmessage = (e)=>{
                    console.log('Получено сообщение');
                    var inData = JSON.parse(e.data);
                    this.onMessage(inData);
                }
                this.socket.onclose = (e)=>{
                    if(!e.wasClean){
                        this.Err(true, 'errConnection', 'Отсутствует соединение!');
                        setTimeout(()=>{
                            this.Connect(true);
                        }, 3000);
                    }
                }
            }
            this.socket.onerror = (e)=>{
                this.Err(true, 'errConnection', 'Отсутствует соединение!');
                setTimeout(()=>{
                    console.log("Ошибка " + e.message);
                    this.Connect(true);
                }, 3000);
            }
        },
        Err(flag, type, msg){
            this.err.flag = flag;
            this.err.type = type || 'unknown';
            this.err.errMsg = msg || 'Неизвестная ошибка';
        },
        checkRight(testRight){
            if(this.user.adminRight){
                return true
            }else if(this.user.groups.includes(testRight)){
                return true
            }else{
                return false;
            }
        }
    },
    created(){
         this.Connect(false);
    }
}


</script>

<style scoped>
.info {
    position: fixed;
    right: 20px;
    bottom: 40px;
    z-index: 99999;
}
</style>
