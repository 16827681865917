export default (date, delimiter, time, toinput)=>{
	if(date instanceof Date){

	}else if(!isNaN(parseFloat(date))){
		date = new Date(date);
	}else{
		return null
	}
	time = time||false;
	toinput = toinput||false;
	delimiter = delimiter||'.'
	var day = date.getDate();
	var year = date.getFullYear();
	var month = date.getMonth();
	day = day < 10 ? `0${day}`:day;
	month++;
	month = month < 10 ? `0${month}`:month;
	var norm = (toinput)?`${year}-${month}-${day}`:`${day}${delimiter}${month}${delimiter}${year}`;
	if(time){
		var hour = date.getHours();
		var minutes = date.getMinutes();
		minutes = minutes < 10 ? `0${minutes}`:minutes;
		norm += ` ${hour}:${minutes}`;
	}
	return norm;
}
